import React from "react"
import Layout from "../layouts/index"
import SEO from "../components/seo"

const AboutFr = () => (
  <Layout cssClass="about blog-style">
    <SEO title="A propos" />
    <div className="main-content">
      <h1>A propos</h1>
    </div>
  </Layout>
)

export default AboutFr
